var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.quote)?_c('div',{attrs:{"id":"order-calendar-view-services"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('div',{staticClass:"d-block d-sm-flex justify-content-between align-items-end"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('order.service_tracking'))+" ")]),(_vm.servicesEditionStatus() && _vm.quote.lines)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}}],attrs:{"id":"order-view-service-update-all-services-to-delivered","block":_vm.$store.getters["app/smAndDown"],"variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.updateAllServicesToDelivered()}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('order.update_all_services_to_delivered'))+" ")])]):_vm._e()],1),(_vm.selectedOrganization.id === _vm.quote.seller.id)?_c('div',{staticClass:"d-flex justify-content-start align-items-end mt-1 note-min-width"},[_c('span',[_vm._v(_vm._s(_vm.$t('common.note'))+" "+_vm._s(_vm.$t('order.service_tracking_note')))])]):_vm._e()]),_c('validation-observer',{ref:"order-calendar-view-services-observer"},[_c('b-table',{ref:"order-calendar-view-services",class:['position-relative', 'table-dropdown-action'],attrs:{"thead-class":"text-nowrap","stacked":_vm.$store.getters["app/mdAndDown"],"items":_vm.quote.lines,"fields":_vm.linesDocumentFields,"empty-text":_vm.$t('common.no_record_found')},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(date)",fn:function(data){return [_c('div',[(_vm.servicesEditionDates())?_c('b-form-group',{staticClass:"mt-md-1 input-group"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":("order-view-service-" + (data.item.id) + "-date"),"config":{
                  altInput: true,
                  altFormat: 'F j, Y H:i',
                  dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                  enableTime: true,
                  time_24hr: true,
                  defaultDate: data.item.date,
                  locale: _vm.$i18n.locale,
                  // Permet d'avoir la date affichée en UTC
                  onReady: function (selectedDates, dateStr, instance) {
                    instance.setDate(new Date(selectedDates[0].toISOString()), false);
                  },
                }},on:{"on-close":function($event){return _vm.updateLineDate(data.item)}},model:{value:(data.item.date),callback:function ($$v) {_vm.$set(data.item, "date", $$v)},expression:"data.item.date"}})],1):_c('span',{staticClass:"text-nowrap"},[(data.item.date)?_c('span',[_vm._v(" "+_vm._s(_vm.$moment.utc(data.item.date).format('ddd, MMM Do YYYY HH:mm'))+" ")]):_vm._e()])],1),(data.item.lineClass === 'HangarQuoteLine')?_c('div',[(_vm.servicesEditionDates())?_c('b-form-group',{staticClass:"mt-md-1 input-group"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":("order-view-service-" + (data.item.id) + "-end-at"),"config":{
                  altInput: true,
                  altFormat: 'F j, Y H:i',
                  dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                  enableTime: true,
                  time_24hr: true,
                  defaultDate: data.item.endAt,
                  locale: _vm.$i18n.locale,
                  // Permet d'avoir la date affichée en UTC
                  onReady: function (selectedDates, dateStr, instance) {
                    instance.setDate(new Date(selectedDates[0].toISOString()), false);
                  },
                }},on:{"on-close":function($event){return _vm.updateLineDate(data.item)}},model:{value:(data.item.endAt),callback:function ($$v) {_vm.$set(data.item, "endAt", $$v)},expression:"data.item.endAt"}})],1):_c('span',{staticClass:"text-nowrap"},[(data.item.date)?_c('span',[_vm._v(" "+_vm._s(_vm.$moment.utc(data.item.endAt).format('ddd, MMM Do YYYY HH:mm'))+" ")]):_vm._e()])],1):_vm._e()]}},{key:"cell(status)",fn:function(data){return [(_vm.servicesEditionStatus())?_c('b-form-group',{staticClass:"mt-md-1 input-group"},[_c('enum-select',{attrs:{"id":("order-view-service-" + (data.item.id) + "-status"),"placeholder":_vm.$t('common.status'),"clearable":false,"searchable":false,"enum-class":"quoteLineStatus","required":""},on:{"input":function($event){return _vm.updateLineStatus(data.item)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var value = ref.value;
return [_c('b-badge',{attrs:{"pill":"","variant":("" + (_vm.resolveSupplyLineStatusVariant(value)))}},[_vm._v(" "+_vm._s(_vm._f("enumTranslate")(value,'quote_line_status'))+" ")])]}},{key:"selected-option",fn:function(ref){
                var value = ref.value;
return [_c('b-badge',{attrs:{"pill":"","variant":("" + (_vm.resolveSupplyLineStatusVariant(value)))}},[_vm._v(" "+_vm._s(_vm._f("enumTranslate")(value,'quote_line_status'))+" ")])]}}],null,true),model:{value:(data.item.status),callback:function ($$v) {_vm.$set(data.item, "status", $$v)},expression:"data.item.status"}})],1):_c('span',[_c('b-badge',{attrs:{"pill":"","variant":("" + (_vm.resolveSupplyLineStatusVariant(data.item.status)))}},[_vm._v(" "+_vm._s(_vm._f("enumTranslate")(data.item.status,'quote_line_status'))+" ")])],1)]}}],null,false,3287178733)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }