<template>
  <div v-if="quote" id="order-calendar-view-services">
    <b-card no-body>
      <b-card-body>
        <div class="d-block d-sm-flex justify-content-between align-items-end">
          <h4>
            {{ $t('order.service_tracking') }}
          </h4>
          <b-button
            v-if="servicesEditionStatus() && quote.lines"
            id="order-view-service-update-all-services-to-delivered"
            v-ripple.400
            :block="$store.getters[`app/smAndDown`]"
            variant="outline-primary"
            @click.prevent="updateAllServicesToDelivered()"
          >
            <span class="text-nowrap">
              {{ $t('order.update_all_services_to_delivered') }}
            </span>
          </b-button>
        </div>
        <div v-if="selectedOrganization.id === quote.seller.id" class="d-flex justify-content-start align-items-end mt-1 note-min-width">
          <span>{{ $t('common.note') }} {{ $t('order.service_tracking_note') }}</span>
        </div>
      </b-card-body>
      <validation-observer ref="order-calendar-view-services-observer">
        <b-table
          ref="order-calendar-view-services"
          :class="['position-relative', 'table-dropdown-action']"
          thead-class="text-nowrap"
          :stacked="$store.getters[`app/mdAndDown`]"
          :items="quote.lines"
          :fields="linesDocumentFields"
          :empty-text="$t('common.no_record_found')"
        >
          <!-- LOADING -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <!-- CELL -->
          <!-- date -->
          <template #cell(date)="data">
            <div>
              <b-form-group v-if="servicesEditionDates()" class="mt-md-1 input-group">
                <flat-pickr
                  :id="`order-view-service-${data.item.id}-date`"
                  v-model="data.item.date"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'F j, Y H:i',
                    dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                    enableTime: true,
                    time_24hr: true,
                    defaultDate: data.item.date,
                    locale: $i18n.locale,
                    // Permet d'avoir la date affichée en UTC
                    onReady: (selectedDates, dateStr, instance) => {
                      instance.setDate(new Date(selectedDates[0].toISOString()), false);
                    },
                  }"
                  @on-close="updateLineDate(data.item)"
                />
              </b-form-group>
              <span v-else class="text-nowrap">
                <span v-if="data.item.date">
                  {{ $moment.utc(data.item.date).format('ddd, MMM Do YYYY HH:mm') }}
                </span>
              </span>
            </div>
            <div v-if="data.item.lineClass === 'HangarQuoteLine'">
              <b-form-group v-if="servicesEditionDates()" class="mt-md-1 input-group">
                <flat-pickr
                  :id="`order-view-service-${data.item.id}-end-at`"
                  v-model="data.item.endAt"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'F j, Y H:i',
                    dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                    enableTime: true,
                    time_24hr: true,
                    defaultDate: data.item.endAt,
                    locale: $i18n.locale,
                    // Permet d'avoir la date affichée en UTC
                    onReady: (selectedDates, dateStr, instance) => {
                      instance.setDate(new Date(selectedDates[0].toISOString()), false);
                    },
                  }"
                  @on-close="updateLineDate(data.item)"
                />
              </b-form-group>
              <span v-else class="text-nowrap">
                <span v-if="data.item.date">
                  {{ $moment.utc(data.item.endAt).format('ddd, MMM Do YYYY HH:mm') }}
                </span>
              </span>
            </div>
          </template>
          <!-- status -->
          <template #cell(status)="data">
            <b-form-group v-if="servicesEditionStatus()" class="mt-md-1 input-group">
              <enum-select
                :id="`order-view-service-${data.item.id}-status`"
                v-model="data.item.status"
                :placeholder="$t('common.status')"
                :clearable="false"
                :searchable="false"
                enum-class="quoteLineStatus"
                required
                @input="updateLineStatus(data.item)"
              >
                <template #option="{ value }">
                  <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                    {{ value | enumTranslate('quote_line_status') }}
                  </b-badge>
                </template>
                <template #selected-option="{ value }">
                  <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                    {{ value | enumTranslate('quote_line_status') }}
                  </b-badge>
                </template>
              </enum-select>
            </b-form-group>
            <span v-else>
              <b-badge pill :variant="`${resolveSupplyLineStatusVariant(data.item.status)}`">
                {{ data.item.status | enumTranslate('quote_line_status') }}
              </b-badge>
            </span>
          </template>
        </b-table>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'

import {
  patchQuoteQuoteLinesDatesRequest,
  patchQuoteQuoteLinesStatusRequest,
} from '@/request/globalApi/requests/quoteRequests'
import {mapState} from 'vuex'

export default {
  name: 'OrdersCalendarOrderResumeServices',

  mixins: [UIHelper],

  props: {
    quote: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState('auth', ['selectedOrganization']),

    linesDocumentFields() {
      return [
        {
          key: 'name',
          label: this.$t('common.name'),
        },
        {
          key: 'date',
          label: `${this.$t('common.date')} ${this.$t('common.utc')}`,
        },
        {
          key: 'status',
          label: this.$t('common.status'),
          thStyle: 'width: 18em',
          tdStyle: 'width: 18em',
        },
      ]
    },
  },

  methods: {
    updateLineDate(line) {
      if (line.date) {
        patchQuoteQuoteLinesDatesRequest(
          this.quote.id,
          { quoteLinesDates: [{ quoteLineId: line.id, date: line.date, endAt: line.endAt }] },
          this.quoteTitleAndRef(),
        )
          .then(() => {
            /*this.$emit('refetch-quote')*/
            this.$emit('refetch-calendar')
          })
      }
    },

    updateLineStatus(line) {
      patchQuoteQuoteLinesStatusRequest(
        this.quote.id,
        { quoteLinesStatus: [{ quoteLineId: line.id, status: line.status }] },
        this.quoteTitleAndRef(),
      )
        .then(() => {
          this.$emit('refetch-quote')
        })
    },

    updateAllServicesToDelivered() {
      const quoteLinesStatus = []
      this.quote.lines.forEach(line => {
        quoteLinesStatus.push({ quoteLineId: line.id, status: 'delivered' })
      })
      patchQuoteQuoteLinesStatusRequest(this.quote.id, { quoteLinesStatus }, this.quoteTitleAndRef())
        .then(() => {
          this.$emit('refetch-quote')
        })
    },

    // Issue #MFC2-781
    servicesEditionDates() {
      return this.quote?.state !== 'delivered'
        && this.quote?.state !== 'claimed'
        && !this.quote.cancelled
        && this.selectedOrganization.id === this.quote.seller.id
    },

    // Issue #MFC2-781
    servicesEditionStatus() {
      return this.quote?.state !== 'delivered'
        && !this.quote.cancelled
        && (this.selectedOrganization.id === this.quote.seller.id || this.selectedOrganization.operator)
        && this.quote?.state !== 'awaiting_for_quotation'
        && this.quote?.state !== 'awaiting_customer_validation'
        && this.quote?.state !== 'claimed'
    },

    quoteTitleAndRef() {
      let quoteTitle = ''
      if (this.quote.state === 'awaiting_for_quotation') quoteTitle = this.$t('supply_document.type.quotation_request')
      if (this.quote.state === 'awaiting_customer_validation') quoteTitle = this.$t('supply_document.type.quotation')
      if (this.quote.state === 'awaiting_for_services_completed' || this.quote.state === 'delivered' || this.quote.state === 'claimed' || this.quote.state === 'cancellation_applied') quoteTitle = this.$t('supply_document.type.order')

      return `${quoteTitle} #${this.quote.reference}`
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  min-width: 160px;
}
td, th {
  padding: 10px;
}
.note-min-width {
  min-width: 300px;
}
</style>
